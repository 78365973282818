import React from 'react'

const SingleLightingDetails = (props) => {
  return (
    <section id="service-details" className="py100">
    <div className="container">
        <div className="row">
            <div className="col-lg-6">
                <div className="service-details-img">
                    <img alt="" src={props.img} />
                </div>
            </div>
            <div className="col-lg-6">
                <div className="service-detail-text">
                    <div className="left-side-title">
                        <h2 className="pt10">Lighting</h2>
                        <p>At TCI ZEN we focus on the Human factor of lighting experience. A typical human eye will respond to wavelengths from about 400 to 700 nanometers.</p>
                        <p>Relating these wavelengths to a rainbow – remember Red - Orange - Yellow - Green - Blue - Indigo - Violet, or "ROY G BIV" – 
                            Red is at the lower end at 700 nm and violet is at the upper end 400nm</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default SingleLightingDetails