import React from 'react'
import './service.css'
import { Link } from 'react-router-dom'

const ServiceCard = ({item}) => {

    const {imageUrl, title, path} = item


  return (
    <div className="service__item">
      <Link to={path} className='brand'>
        <div className="service__img" >
            <img src={imageUrl} alt="Image3" />
        </div>
        <h3>{title}</h3>
        {/* <p>{desc}</p> */}
        </Link>
    </div>
  )
}

export default ServiceCard