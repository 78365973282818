
import Routers from './routes/Routers';
import './Style/style.css'
function App() {
  return (
    <>
    <Routers/>
    </>
  );
}

export default App;
