import React from 'react'
import styled from 'styled-components';

const Hr = styled.hr`
width: 50%;
height: 1px;
color: #000;
margin-left: auto;
margin-right: auto;
`;
const HorizontalRule = () => {
  return (
    <>
    <Hr/>
    </>
  )
}

export default HorizontalRule