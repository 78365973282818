import React from 'react'

const SingleBroadcastDetails = (props) => {
  return (
    <section id="service-details" className="py100">
    <div className="container">
        <div className="row">
            <div className="col-lg-6">
                <div className="service-details-img">
                    <img alt="" src={props.img} />
                </div>
            </div>
            <div className="col-lg-6">
                <div className="service-detail-text">
                    <div className="left-side-title">
                        <h2 className="pt10">Broadcast</h2>
                        <p>At TCI ZEN we focus on providing exceptional solutions for Broadcasters in collaboration with our partners and using our in house Audio, Video, Lighting and Acoustics capabilities for the following:</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default SingleBroadcastDetails