import React from 'react'
import CommonBanner from '../Components/Common/Banner'
import SingleLightingDetails from '../Components/Lighting/SingleLightingDetails'
import Img from '../Images/Lighting/4.jpg'
import LightingServices from '../Components/Lighting/LightingServices'
const Lighting = () => {
  return (
    <>
    <CommonBanner heading="Lighting" menu1="Home" menu2="Service Details"/>
    <SingleLightingDetails img = {Img}/>
    <LightingServices/>
    </>
  )
}

export default Lighting