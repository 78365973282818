import React from 'react'
import CommonBanner from '../Components/Common/Banner1/index'
import History from '../Components/About/History'
//import GetStarted from '../Components/Common/GetStarted/index'
import '../assets/css/style.css'
import '../assets/css/responsive.css'

const About = () => {
  
  return (
    <>
    <section>
      <CommonBanner heading=""/>
      <History/>
    </section>
    </>
  )
}

export default About