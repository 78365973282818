import React from 'react'
import Hero from '../Components/Hero'
import { SliderData } from '../Data/SliderData'
import InfoSection from '../Components/InfoSection'
import { InfoData } from '../Data/InfoData'
import Serviceitems from '../Components/Service/Serviceitems'
//import Principle from '../Components/Principles/Principle'
import Contact from '../Components/Contact/index'

const Home = () => {
  return (
    <>
    <Hero slides={ SliderData }/>
    <InfoSection {...InfoData} />
    <Serviceitems/>
    {/* <Principle/> */}
    <Contact/>
    </>
  )
}

export default Home