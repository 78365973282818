import Icon1 from '../../../Images/AudioVideo/1.jpg'
import Icon2 from '../../../Images/AudioVideo/2.jpg'
import Icon3 from '../../../Images/AudioVideo/3.jpg'
import Icon4 from '../../../Images/AudioVideo/4.jpg'
import Icon5 from '../../../Images/AudioVideo/5.jpg'
import Icon6 from '../../../Images/AudioVideo/6.jpg'
import Icon7 from '../../../Images/AudioVideo/7.jpg'
import Icon8 from '../../../Images/AudioVideo/8.jpg'
import Icon9 from '../../../Images/AudioVideo/9.jpg'
import Icon10 from '../../../Images/AudioVideo/10.jpg'
import Icon11 from '../../../Images/AudioVideo/11.jpg'

import vIcon1 from '../../../Images/Video/1.jpg'
import vIcon2 from '../../../Images/Video/2.jpg'
import vIcon3 from '../../../Images/Video/3.jpg'
import vIcon4 from '../../../Images/Video/4.jpg'
import vIcon5 from '../../../Images/Video/5.jpg'
import vIcon6 from '../../../Images/Video/6.jpg'

import lIcon1 from '../../../Images/Lighting/1.jpg'
import lIcon2 from '../../../Images/Lighting/2.jpg'
import lIcon3 from '../../../Images/Lighting/3.jpg'
import lIcon4 from '../../../Images/Lighting/4.jpg'

import bIcon1 from '../../../Images/Broadcast/1.jpg'
import bIcon2 from '../../../Images/Broadcast/2.jpg'
import bIcon3 from '../../../Images/Broadcast/3.jpg'
import bIcon4 from '../../../Images/Broadcast/4.jpg'

import aIcon1 from '../../../Images/Acoustics/1.jpg'
import aIcon2 from '../../../Images/Acoustics/2.jpg'
import aIcon3 from '../../../Images/Acoustics/3.jpg'
import aIcon4 from '../../../Images/Acoustics/4.jpg'
import aIcon5 from '../../../Images/Acoustics/5.jpg'
import aIcon6 from '../../../Images/Acoustics/6.jpg'
import aIcon7 from '../../../Images/Acoustics/7.jpg'



export const OurServiceData = [
{
 img:Icon1,
 heading:"Professional Audio Recording Studios",
 paragraph:"",
 button:"Read More ..."
},
{
 img:Icon2,
 heading:"Auditoriums",
 paragraph:"",
 button:"Read More ..."
},
{
 img:Icon3,
 heading:"House of Worship",
 paragraph:"",
 button:"Read More ..."
},
{
 img:Icon4,
 heading:"Background Music Systems",
 paragraph:"",
 button:"Read More ..."
},
{
 img:Icon5,
 heading:"Live and Broadcast Sound Systems",
 paragraph:"",
 button:"Read More ..."
},
{
 img:Icon6,
 heading:"Sound Masking Solutions",
 paragraph:"",
 button:"Read More ..."
},
{
    img:Icon7,
    heading:"Distributed Audio Systems",
    paragraph:"",
    button:"Read More ..."
},
{
    img:Icon8,
    heading:"Public Address Systems",
    paragraph:"",
    button:"Read More ..."
},
{
    img:Icon9,
    heading:"Banquets and DJ Systems",
    paragraph:"",
    button:"Read More ..."
},
{
    img:Icon10,
    heading:"Nightclubs and Discotheques",
    paragraph:"",
    button:"Read More ..."
},
{
    img:Icon11,
    heading:"Home Theatres",
    paragraph:"",
    button:"Read More ..."
},

// video
{
    img:vIcon1,
    heading:"Video Recording Studios",
    paragraph:"",
    button:"Read More ..."
},
{
    img:vIcon2,
    heading:"Projector and Screen Systems",
    paragraph:"",
    button:"Read More ..."
},
{
    img:vIcon3,
    heading:"Video Conferencing Solutions",
    paragraph:"",
    button:"Read More ..."
},
{
    img:vIcon4,
    heading:"Digital Signage Solutions",
    paragraph:"",
    button:"Read More ..."
},
{
    img:vIcon5,
    heading:"Digital Advertising Solutions",
    paragraph:"",
    button:"Read More ..."
},
{
    img:vIcon6,
    heading:"Video Walls",
    paragraph:"LED Solutions",
    button:"Read More ..."
},

// Lighting
{
    img:lIcon1,
    heading:"Stage Lighting Solutions ",
    paragraph:"LED Solutions",
    button:"Read More ..."
},
{
    img:lIcon2,
    heading:"Façade Lighting Solutions",
    paragraph:"LED Solutions",
    button:"Read More ..."
},
{
    img:lIcon3,
    heading:"Lighting Control and Automation",
    paragraph:"LED Solutions",
    button:"Read More ..."
},
{
    img: lIcon4,
    heading:"Studio Lighting Solutions",
    paragraph:"LED Solutions",
    button:"Read More ..."
},

// BroadCast
{
    img: bIcon2,
    heading:"Broadcast Automation Solutions",
    paragraph:"LED Solutions",
    button:"Read More ..."
},
{
    img: bIcon3,
    heading:"Signal Transportation Solutions",
    paragraph:"LED Solutions",
    button:"Read More ..."
},
{
    img: bIcon4,
    heading:"Content Editing and Publishing Solutions",
    paragraph:"LED Solutions",
    button:"Read More ..."
},
{
    img: bIcon1,
    heading:"Studio Design and Creation",
    paragraph:"LED Solutions",
    button:"Read More ..."
},

// Acoustics

{
    img: aIcon1,
    heading:"Studios",
    paragraph:"LED Solutions",
    button:"Read More ..."
},
{
    img: aIcon3,
    heading:"Classrooms",
    paragraph:"LED Solutions",
    button:"Read More ..."
},
{
    img: aIcon4,
    heading:"Theatres",
    paragraph:"LED Solutions",
    button:"Read More ..."
},
{
    img: aIcon5,
    heading:"Conference Rooms",
    paragraph:"LED Solutions",
    button:"Read More ..."
},
{
    img: aIcon6,
    heading:"Sound Insulation Solutions",
    paragraph:"LED Solutions",
    button:"Read More ..."
},
{
    img: aIcon7,
    heading:"Vibration Control Solutions",
    paragraph:"LED Solutions",
    button:"Read More ..."
},
{
    img: aIcon2,
    heading:"Auditoriums",
    paragraph:"LED Solutions",
    button:"Read More ..."
},

]
