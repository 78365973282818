import React from 'react'
import CommonBanner from '../Components/Common/Banner'
import SingleAcousticsDetails from '../Components/Acoustics/SingleAcousticsDetails'
import AcousticsServices from '../Components/Acoustics/AcousticsServices'
import Img from '../Images/Acoustics/2.jpg'
const Acoustics = () => {
  return (
    <>
    <CommonBanner heading="Acoustics" menu1="Home" menu2="Service Details"/>
    <SingleAcousticsDetails img = {Img}/>
    <AcousticsServices/>
    </>
  )
}

export default Acoustics
