import React from 'react'

const SingleServiceDeatils = (props) => {
    return (
        <>
            <section id="service-details" className="py100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="service-details-img">
                                <img alt="" src={props.img} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="service-detail-text">
                                <div className="left-side-title">
                                    <h2 className="pt10">Audio</h2>
                                    <p>Music is an unmatched Human Obsession and we deal with the Science of Music Production and Voice is a basic necessity for Human Survival, we at TCI ZEN take both the factors about Sound and its propagation in an area to deliver the best Audible Experience to the end user keeping in mind the basics of sound propagation, sound wave characteristics, harmonics, the decibel, and sound environment.</p>
                                    <p>We explore how these basics apply to the electrical pathway used to amplify sound and trace the audio-signal chain from start to finish- microphones to loudspeakers. Signal levels, cables used, and types of circuits, making informed audio decisions.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SingleServiceDeatils
