import React from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import Home from '../Pages/Home';
import About from '../Pages/About';
import Services from '../Pages/Services'
import AudioVideo from '../Pages/AudioVideo';
import Video from '../Pages/Video';
import Contact from '../Components/Contact/index'
import Lighting from '../Pages/Lighting';
import Broadcast from '../Pages/Broadcast';
import Acoustics from '../Pages/Acoustics';
import Partners from './Partners/Partners';
 
const Layout = () => {
  return (
    <>
    <Routes>
        <Route path ='/' element = {<Navigate to = '/home'/>}/>
        <Route path='/TCIZEN' element = {<Navigate to = '/home'/>}/>
        <Route index path = '/home' element = {<Home/>}/>
        <Route path = '/about' element = {<About/>}/>
        <Route path = '/services' element = {<Services/>}/>
        <Route path='/contact' element = {<Contact/>}/>
        <Route path = '/av' element = {<AudioVideo/>}/>
        <Route path='/video' element = {<Video/>}/>
        <Route path='/lighting' element = {<Lighting/>}/>
        <Route path = '/broadcast' element = {<Broadcast/>}/>
        <Route path = '/acoustics' element = {<Acoustics/>}/>
        <Route path ='/partners' element = {<Partners/>}/>
    </Routes>
    </>
  )
}

export default Layout