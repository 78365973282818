import React from 'react'
import { Col } from 'reactstrap'

import ServiceCard from '../Components/Service/ServiceCard'

import Image1 from "../Images/Service Image/voiceprint-fill.svg"
import Image2 from '../Images/Service Image/flashlight-fill.svg'
import Image3 from '../Images/Service Image/broadcast-line.svg'
import Image4 from '../Images/Service Image/soundcloud-line.svg'
const serviceData =[

    {
        imageUrl: Image1,
        title: "AV",
        desc: "Our AV specialists deliver captivating sound and visual experiences that employ your senses and enhance your ability to engage, connect.",
        path: '/av',
    },
    {
        imageUrl: Image2,
        title: "Lighting",
        desc: "IT has found its way into almost every aspect of our daily lives. Make IT invisible and your space runs seamlessly through carefully integrated services that enhance user experiences.",
        path: '/lighting',
    },
    {
        imageUrl: Image3,
        title: "Broadcast",
        desc: "Create phenomenal theatre experiences through meticulously planned technical elements such as lighting, rigging and sound from the moment you step inside.",
        path: '/broadcast',
    },
    {
        imageUrl: Image4,
        title: "Acoustics",
        desc: "Create phenomenal theatre experiences through meticulously planned technical elements such as lighting, rigging and sound from the moment you step inside.",
        path: '/acoustics',
    },
]

const ServiceData = () => {
  return (
    <>
        {
        serviceData.map((item, index) =><Col lg="3" key={index}>
            <ServiceCard item = {item}/>
        </Col>)
    }
    </>
  )
}

export default ServiceData