import React from 'react'
import CommonBanner from '../Components/Common/Banner'
import ServiceGraph from '../Components/ServiceDetails/ServiceGraph'
import VideoService from '../Components/ServiceDetails/VideoService'
import img1 from '../Images/Video/7.jpg'
const Video = () => {
  return (
   <>
    <CommonBanner heading="Video" menu1="Home" menu2="Service Details"/>
    <ServiceGraph img1 = {img1}/>
    <VideoService/>
   </>
  )
}

export default Video