import React from 'react'
import { CDBNavLink, CDBBtn, CDBIcon, CDBBox} from 'cdbreact';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { IoMdLocate } from 'react-icons/io';
import { MdMail, MdPhone } from 'react-icons/md'
import './footer.css'
import Logo from '../../Images/Logo/final_image_png.png'
//import { ListGroup, ListGroupItem } from 'reactstrap';





const SectionFooter = styled.section`
width: 100%;
color: #fff;
background-color: #000;
`;

const Footer = () => {
  return (
    <SectionFooter>
    <CDBBox display="flex" flex="column" className="mx-auto py-5">
      <CDBBox display="flex" justifyContent="between" className="flex-wrap footer-flex">
        <CDBBox alignSelf="center" style={{marginLeft: '5%'}}>
          <Link to="/" className="d-flex align-items-center p-0 text-dark">
          <img src={Logo} alt="Logo" style={{width:'300px'}} />
          </Link>
          <CDBBox className="mt-5" display="flex">
            <CDBBtn flat color="dark" className="p-2">
              <CDBIcon fab icon="facebook-f" />
            </CDBBtn>
            <CDBBtn flat color="dark" className="mx-3 p-2">
              <CDBIcon fab icon="twitter" />
            </CDBBtn>
            <CDBBtn flat color="dark" className="p-2">
              <CDBIcon fab icon="instagram" />
            </CDBBtn>
          </CDBBox>
        </CDBBox>
        <CDBBox style={{width:'40%'}}>
          <p className="h4 mb-3" style={{ fontWeight: '500', textAlign: 'left' }}>
            Address
          </p>
          <CDBBox display="flex" flex="column" style={{ cursor: 'pointer', textAlign: 'left'}}>
            <CDBNavLink className='h4 mb-2'>U.A.E Office: TCI ZEN Dubai</CDBNavLink>
            <CDBNavLink className='h5 mb-3'style={{fontWeight: '100'}} ><IoMdLocate/>  Honey Bussiness Centre 
                        Office No 407-19 , 4 th floor , <br /> Sheikha Mariam Building No  P -114 , Baniyas ,Deira, Dubai
            </CDBNavLink>
            <CDBNavLink className='h2 mb-2'>India Office: TCI ZEN Consultants Pvt. Ltd.</CDBNavLink>
            <CDBNavLink className='h5 mb-3'style={{fontWeight: '100'}} ><IoMdLocate/>  
                #616, 6th Floor, The I-Thum Tower, Tower - A, A-40, <br /> Sector-62, Noida, U.P, 
              Near Noida Electronic City Metro Station
            </CDBNavLink>
          </CDBBox>
        </CDBBox>
        <CDBBox style={{width: '20%'}}>
          <p className="h4 mb-4" style={{ fontWeight: '600', textAlign: 'left' }}>
            Contact Us
          </p>
          <CDBBox display="flex" flex="column" style={{ cursor: 'pointer', textAlign: 'left'}}>
            
            <CDBNavLink className='mb-3' style={{fontSize: '1.2rem'}}><MdPhone/> +971 50 107 7313 </CDBNavLink>
            <CDBNavLink className='mb-3' style={{fontSize: '1.2rem'}}><MdPhone/> +91 94243 52040 </CDBNavLink>
            <CDBNavLink className='h5 mb-3' style={{fontSize: '1.2rem'}}><MdMail/> zen@zentci.com  </CDBNavLink>
            {/* <CDBNavLink path="/broadcast">Broadcast</CDBNavLink>
            <CDBNavLink path="/acoustics">Acoustics</CDBNavLink> */}
          </CDBBox>
        </CDBBox>
        {/* <CDBBox>
          <p className="h5 mb-4" style={{ fontWeight: '600', textAlign: 'left' }}>
            Contact us
          </p>
          <CDBBox marginLeft = '1rem' display="flex" flex="column" style={{ cursor: 'pointer', textAlign: 'left' }}>
            <CDBNavLink path="/"><i class="ri-phone-fill"></i>Phone</CDBNavLink>
            <CDBNavLink path="/">Email</CDBNavLink>
            <CDBNavLink path="/">Address</CDBNavLink>
          </CDBBox>
        </CDBBox> */}
      </CDBBox>
      <small className="text-center mt-5">&copy; TCIZEN, 2023. All rights reserved.</small>
    </CDBBox>
  </SectionFooter>
  )
}

export default Footer