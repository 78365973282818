export const menuData = [
    {
        title: 'Home', 
        link: '/home',

    },
    {
        title: 'About', 
        link: '/about'
    },
    {
        title: 'Service', 
        link: '/services',
        has_childern: true,
        childern: [
            {
                title: 'AV',
                link: '/av',
            },
            {
                title: 'Lighting',
                link: '/lighting',
            },
            {
                title: 'Broadcast',
                link: '/broadcast',
            },
            {
                title: 'Aquastics',
                link: '/aquastics',
            },
        ]
    },
]