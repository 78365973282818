import React from 'react'
import ContactForm from './Contactform'

const ContactDetails = () => {
    return (
        <>
            <section id="contact">
                <div className='map'>
                <iframe src="https://www.google.com/maps/d/u/6/embed?mid=1efuh2_GkwxuNOFsLOAImtrnHhkrXNac&ehbc=2E312F"
                title='map' style={{border:"0"}}  
                width="100%" 
                height="480"
                allowFullScreen = " "
                loading='lazy'></iframe> 
                </div>
                <div className="contact-margin container">
                    <div className="padding-areas">
                        <h3>Have a word with us</h3>
                            <div className="contact-margin col-lg-8  col-md-12 col-sm-12 col-12">
                                <ContactForm/>
                            </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactDetails
