import ImageOne from '../Images/ImageOne.jpg'
import ImageTwo from '../Images/ImageTwo.jpg'
import ImageThree from '../Images/ImageThree.jpg'
import ImageFour from '../Images/ImageFour.jpg'

export const SliderData = [
    {
        title: 'Acoustics',
        title1: '',
        path: '/home',
        image: ImageOne,
        label: 'View Room',
        alt: 'House',
    },
    {
        title: 'Audio Visual',
        title1: '',
        path: '/home',
        image: ImageThree,
        label: 'View Room',
        alt: 'House',
    },
    {
        title: 'Lighting',
        title1: '',
        path: '/home',
        image: ImageTwo,
        label: 'View Room',
        alt: 'House',
    },
    {
        title: 'Broadcast',
        title1: '',
        path: '/home',
        image: ImageFour,
        label: 'View Room',
        alt: 'House',
    },   
];
