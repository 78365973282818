import React from 'react'

const index = (props) => {
  return (
    <>
    <section id='banner-inner-area1'>
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="banner-details text-center">
                        <h2>{props.heading}</h2>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default index