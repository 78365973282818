import React from 'react'
import img1 from '../../assets/img/about/1.png'
import img2 from '../../assets/img/about/engineer-4922420.jpg'
import img3 from '../../assets/img/about/online-meeting-5183791.jpg'
import {FiCheck} from 'react-icons/fi'
import Team from '../About/Team/index'

import '../../assets/css/style.css'

const History = () => {
  return (
    <>
    {/* About Top Area Start */}
    <section id="about-top" className="py100 bg-color">
        <div className="container">
            <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="about-top-img animation-img-two">
                        <img alt="" src={img1} />
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="about-img">
                        <h2>Our Vision</h2>
                        {/* <p>
                        To be the most preferred Infrastructure and Solutions Provider 
                        </p> */}
                        <h5>Spreading happiness through the Sound and Visual senses.</h5>
                        <p>
                         Through:
                        </p>

                        <ul>
                            <li><i><FiCheck/></i>Professional Audio Systems Solution</li>
                            <li><i><FiCheck/></i>Professional Video Systems Solution</li>
                            <li><i><FiCheck/></i>Professional Lighting Systems Solution</li>
                            <li><i><FiCheck/></i>Broadcast Systems Solution</li>
                            <li><i><FiCheck/></i>Acoustic Design & Consultancy</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row" style={{marginTop: "1rem"}} >
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="about-img">
                        <h2>Our Evolution</h2>
                        <p>
                        TCI ZEN is a Pro-Audio, Video, Broadcast, Lighting & Acoustics Design and Consultancy. 
                        Since its inception, TCI ZEN have come a long way. From a 'me too' company TCI ZEN has been 
                        able to create a niche for itself in the fiercely competitive environment.
                        </p>
                        <p>This has 
                        been made possible by incorporating our vision of providing solutions to our business 
                        partners to maximize the business benefits by enabling technology.</p>
                        <p>
                        The company formed and managed by professionals who are dreamers, 
                        and who dream the most do the most, with the required expertise and know-how. 
                        All this with an uncanny ability to sense the future potential.
                        </p>
                        <p>Strong technical 
                        expertise is blended with hard-core marketing principles to cultivate a whole new 
                        set of ethos, work culture and technological break through. As a result, a better 
                        and brighter support to our clients.</p>
                        {/* <ul>
                            <li><i><FiCheck/></i>Licensed OCR tool</li>
                            <li>
                                <i><FiCheck/></i>Hadoop and other Big data
                                platforms
                            </li>
                            <li><i><FiCheck/></i>Luigi workflow manager</li>
                            <li>
                                <i><FiCheck/></i>Distributed setup on Cloud for speed
                            </li>
                            <li><i><FiCheck/></i>Django framework</li>
                        </ul> */}
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="about-top-img animation-img-two">
                        <img alt="" src={img2} />
                    </div>
                </div>
            </div>
            <div className="row" style={{marginTop:"2rem"}}>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="about-top-img animation-img-two" >
                        <img alt="" src={img3} style={{paddingTop: '2rem'}} />
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="about-img">
                        <h2>Our Strength</h2>
                        <p>
                        TCI ZEN Private Limited offers expert consultation, planning, designing, implementation, training and maintenance of Pro-Audio, video, lighting, Broadcast & Acoustic Projects.
                        </p>
                        <p>
                        Our focus is on the creation of an exceptional training and works environment that engages client and fellow employee alike that harnesses enthusiasm and allows for explosive growth both in the skill set of its staff and knowledge of its clients. In addition to:
                        </p>

                        <ul>
                            <li><i><FiCheck/></i>Thoroughly understanding our client’s requirements by addressing their 100% needs and wants. </li>
                            <li><i><FiCheck/></i>Working with industry to gain intellectual capital so as to develop innovative & effective program.</li>
                            <li><i><FiCheck/></i>Providing effective technology training program to the clients.</li>
                            <li><i><FiCheck/></i>Assisting pre-services and in-services monitoring, as well as administration. </li>
                            <li><i><FiCheck/></i>Dynamic, aggressive, challenging work environment.</li>
                        </ul>
                        <p>
                        ensuring cohesive work culture, we endeavor to offer the best state of art technology to the growing industry. 
                        </p>
                    </div>
                </div>
            </div>

            <Team/>

        </div>
    </section>
    {/* About Top Area Start */}
</>
  )
}

export default History