import React from 'react'

const SingleAcousticsDetails = (props) => {
  return (
    <section id="service-details" className="py100">
    <div className="container">
        <div className="row">
            <div className="col-lg-6">
                <div className="service-details-img">
                    <img alt="" src={props.img} />
                </div>
            </div>
            <div className="col-lg-6">
                <div className="service-detail-text">
                    <div className="left-side-title">
                        <h2 className="pt10">Acoustics</h2>
                        <p>At TCI ZEN we focus on the fundamental parts of hearing, Hearing is one of the most crucial means of survival in the animal world and speech is one of the most distinctive characteristics of human development and culture. Accordingly, the science of acoustics spreads across many facets of human society—music, medicine, architecture, industrial production, warfare and more.</p>
                         <p>We have industry proven experts in house who provide the most optimum Acoustics solutions for the listeners in any type of environment, also we have all the necessary tools for the proper measurement and testing of Acoustics for all types of spaces.</p>
                        <p>We provide sound absorption as well as isolation solutions depending on the nature of work and also specialize in Noise and Vibration control.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default SingleAcousticsDetails