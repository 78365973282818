import React from 'react'
import styled from 'styled-components'
import HorizontalRule from './HorizontalRule';


const Section = styled.section`
margin-top: 2rem;
width: 100%;
height: 100%;
padding: 4rem 0rem;
`;
const Heading = styled.div`
position: relative;
align-items: center;
text-align: center;
font-family: 'Poppins', sans-serif;
z-index: 10;
display: block;
flex-direction: coloumn;
max-width: 1600px;
width: calc(100% - 100px);
h1{
    font-size: clamp(2rem, 10vw, 3rem);
    position: relative;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0.8rem;
}
h3{
  margin-top: 4rem;
}
p{
    text-align: center;
    font-size: 1.2rem;
    padding-left: 10%;
    padding-right: 10%;
    color: #6e7074;
}


`;
const InfoSection = ({heading,paragraphOne, heading1, paragraphTwo}) => {
  return (
    <Section className='content'>
        <Heading>
            <h1>{heading}</h1>
            <HorizontalRule/>
            <p>{paragraphOne}</p>
            <p>{paragraphTwo}</p>
        </Heading>
    </Section>
  )
}

export default InfoSection