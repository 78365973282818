import React from 'react'
import CommonBanner from '../Components/Common/Banner/index'
import SingleBroadcastDetails from '../Components/Broadcast/SingleBroadcastDetails'
import BroadcastServices from '../Components/Broadcast/BroadcastServices'
import Img from '../Images/Broadcast/1.jpg'

const Broadcast = () => {
  return (
    <>
    <CommonBanner heading="Broadcast" menu1="Home" menu2="Service Details" />
    <SingleBroadcastDetails img = {Img}/>
    <BroadcastServices/>
    </>
  )
}

export default Broadcast