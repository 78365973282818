import img1 from '../../../assets/img/team/Image2.JPG'
import img2 from '../../../assets/img/team/Image1.jpeg'
import img3 from '../../../assets/img/team/Untitled design.png'

export const TeamData = [
    // {
    //     img: img1,
    //     name: "Mr. RISHAB SURI",
    //     title: "Principal Consultant"
    // },
    {
        img: img3,
        name: "Mr. Raman Uppal",
        title: "Business Head"
    },
    {
        img: img2,
        name: "Ms. Prachi Pandey",
        title: "Head Legal and HR"
    },
]
