import React from 'react'
import '../Style/style.css'
import Commonbanner from "../Components/Common/Banner"
import Counter from '../Components/Common/Counter'
import Serviceitems from '../Components/Service/Serviceitems'
import GetStarted from '../Components/Common/GetStarted'
import Blog from '../Components/Home/Blog/Blog'
const Services = () => {
  return (
    <>
    <Commonbanner heading = 'Services'/>
    <Counter/>
    <Serviceitems/>
    <GetStarted/>
    <Blog/>
    </>
  )
}

export default Services