import React from 'react'
import ServiceData from '../../Data/ServiceData'
import { Container, Row, } from 'reactstrap'
const Serviceitems = () => {
  return (
    <section className='service'>
    <h1>Our Services</h1>
    <Container className='container'>
      <div className='consultantImage'>
      <i class="ri-question-answer-fill"/>
      </div>
        <h2>Consultation, Turnkey Solution and Maintenance</h2>
        <hr />
        {/* <p>As one of the industry’s leading independent acoustics, AV, IT and theatre consultancy practices, we don’t just design technology solutions, we also look at how technology amplifies the human experience.</p> */}
        <Row lg='2'>
          <ServiceData/>
        </Row>
    </Container>
  </section>
  )
}

export default Serviceitems