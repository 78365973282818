import React from 'react'
import {BsCheckCircle} from "react-icons/bs";

function ServiceGraph(props) {
    return (
        <>
            <section id="service-img-details" className="py100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                            <div className="service-img-details">
                                <img alt="" src={props.img1} />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <div className="service-img-text">
                                <h3>Video</h3>
                                <p>At TCI ZEN we deal with the basics of light by exploring its properties and behaviours as a wave. 
                                    Video signals and aspects that affect their quality, along with visual systems standards. </p>
                                <ul>
                                    <li><i><BsCheckCircle/></i>Exceptional Video Experiences</li>
                                    <li><i><BsCheckCircle/></i>Human factors of Vision and Perception</li>
                                    <li><i><BsCheckCircle/></i>Video Signals</li>
                                    <li><i><BsCheckCircle/></i>Display and Projection Technologies</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ServiceGraph
