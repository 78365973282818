import React, {useState} from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

//import logos
import img1 from '../../assets/img/logo/techconnect-1/1.png'
import img2 from '../../assets/img/logo/techconnect-1/2.png'
import img3 from '../../assets/img/logo/techconnect-1/3.png'
import img4 from '../../assets/img/logo/techconnect-1/4.png'
import img5 from '../../assets/img/logo/techconnect-1/5.png'
import img6 from '../../assets/img/logo/techconnect-1/6.png'
import img7 from '../../assets/img/logo/techconnect-1/7.png'
import img8 from '../../assets/img/logo/techconnect-1/8.png'

const PartnerSystemIntigrationLogo = () => {

    const [settings] = useState({
        dots: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
    });

    const images =[
        img1,
        img2,
        img3,
        img4,
        img5,
        img6,
        img7,
        img8,
    ];

  return (
    <div>
        <Slider {...settings}>
            {images.map((image, index) => (
                <div key={index} className='container'>
                    <img src={image} alt="logo" className='h-10 w-20 align-item-center' />
                </div>
            ))}
        </Slider>
    </div>
  )
}

export default PartnerSystemIntigrationLogo