import React, { useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
//import { Button } from './Button';
//import {IoMdArrowRoundForward} from 'react-icons/io'
import { IoMdArrowForward, IoMdArrowBack } from 'react-icons/io';

const HeroSection = styled.section`
height: 100vh;
max-height: 1100px;
position: relative;
overflow: hidden; 
`;

const HeroWrapper = styled.div`

width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
overflow: hidden;
position: relative;
`;

const HeroSlide = styled.div`
z-index: 1;
width: 100%;
height: 100%;
`;
const HeroSlider = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;

&::before{
    content: '';
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100vh;
    bottom: 0vh;
    left: 0;
    overflow: hidden;
    opacity: 0.4;
    background: linear-gradient(0deg, 
        rgba(0, 0, 0, 0.2) 0%, 
        rgba(0, 0, 0, 0.2) 50%, 
        rgba(0, 0, 0, 0.6) 100%)
}
`;
const HeroImage = styled.img`
position: absolute;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
object-fit: cover;

`;
const HeroContent = styled.div`
position: relative;
align-items: center;
text-align: center;
z-index: 10;
display: block;
flex-direction: coloumn;
max-width: 1600px;
width: calc(100% - 100px);
color: #fff;
p{
    margin-bottom: 1.2rem;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    color: #fff;
}
h3{
    color:#fff;
}
hr{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    height: 2px;
    border-width:0;
    color:#fff;
    background-color: #fff;
}

h1{
    font-family: 'Lobster', cursive;
    font-size: 5rem;
}

@media only screen and (max-width: 991px){
    h1{
        font-size: 2.5rem;
    }
    hr{
        1px
    }
}

`;
// const Arrow = styled(IoMdArrowRoundForward)`
// margin-left: 0.5rem;
// `;

const SliderButtons = styled.div`
position: absolute;
bottom: 50px;
right: 50px;
display: flex;
z-index: 50;
`;

const arrowButtons = css`
width: 50px;
height: 50px;
color: #fff;
cursor: pointer;
background: #000d1a;
border-radius: 50px;
padding: 10px;
margin-right: 1rem;
user-select: none;
transition: 0.3s;
&:hover {
    background: #cd953f;
    tranfrom: scale(1.05);
}
`;

const PrevArrow = styled(IoMdArrowBack)`
${arrowButtons}
`;

const NextArrow = styled(IoMdArrowForward)`
${arrowButtons}
`;

const Hero = ({slides}) => {

const [current, setcurrent ] = useState(0)
const length = slides.length
const timeout = useRef(null)

useEffect(() =>{
    const nextSlide = () => {
        setcurrent(current => (current === length - 1 ? 0 : current + 1 ))
    }

    timeout.current = setTimeout(nextSlide, 3000)

    return function () {
        if(timeout.current){
            clearTimeout(timeout.current)
        }
    }
}, [current, length])

const nextSlide = () => {
    if(timeout.current){
        clearTimeout(timeout.current);
    }
    setcurrent(current === length - 1 ? 0 : current + 1)
//    console.log(current)
}

const prevSlide = () => {
    if(timeout.current){
            clearTimeout(timeout.current);
        }
    setcurrent(current === 0 ? length - 1 : current -1)
//    console.log(current)
}

if(!Array.isArray(slides) || slides.length <= 0){
    return null
}

  return (
    <HeroSection>
        <HeroWrapper>
        {
            slides.map( (slide, index) => {
                return(
                    <HeroSlide key={index}>
                        {index === current && (
                            <HeroSlider className='herocontent'>
                            <HeroImage src = {slide.image} alt={slide.alt} />
                            <HeroContent>
                                <hr />
                                <h1>{slide.title}</h1>
                                <hr />
                                {/* <h3>{slide.title1}</h3> */}
                                {/* <Button to = {slide.path} primary = 'true' css = {`maz-width: 160px;`}>
                                    {slide.label}
                                    <Arrow/>
                                </Button> */}
                            </HeroContent>
                        </HeroSlider>
                        )}
                    </HeroSlide>
                )
            })
        }

        <SliderButtons>
            <PrevArrow onClick={prevSlide}/>
            <NextArrow onClick={nextSlide}/>
        </SliderButtons>
        </HeroWrapper>
    </HeroSection>
  )
}

export default Hero