import React from 'react'
import CommonBanner from '../Components/Common/Banner'
import SingleServiceDeatils from '../Components/ServiceDetails/SingleServiceDeatils'
import RelatedServices from '../Components/ServiceDetails/RelatedServices'
import img from '../Images/AudioVideo/12.png'
const AudioVideo = () => {
  return (
    <>
    <CommonBanner heading="Audio" menu1="Home" menu2="Service Details"/>
    <SingleServiceDeatils img = {img}/>
    <RelatedServices/>
</>
  )
}

export default AudioVideo