import React, { useRef } from 'react'
import FormInput from "../From/index";
import emailjs from '@emailjs/browser'

const ContactForm = () => {

    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_d0gjyub', 'template_oc98ieo', form.current, 'dH2dvttxsJTdG1A2O')
        .then((result) => {
            console.log(result.status);
            }, (error) => {
                console.log(error.text);
            });
            
    };


    return (
        <>
            <div className="contact-form-area box-shadow">
                <form ref={form} onSubmit={sendEmail}>
                    <div className="row">
                        <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                            <FormInput
                                tag={'input'}
                                type={'text'}
                                name={'first_name'}
                                classes={'form-control'}
                                placeholder={'First Name *'}
                            />
                        </div>
                        <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                            <FormInput
                                tag={'input'}
                                type={'text'}
                                name={'last_name'}
                                classes={'form-control'}
                                placeholder={'Last Name *'}
                            />
                        </div>
                        <div className="col-lg-12  col-md-12 col-sm-12 col-12">
                            <div className="form-group">
                                <FormInput
                                    tag={'input'}
                                    type={'text'}
                                    name={'user_email'}
                                    classes={'form-control'}
                                    placeholder={'Enter Your Mail Here....'}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="form-group">
                                <FormInput
                                    tag={'textarea'}
                                    name={'message'}
                                    classes={'form-control'}
                                    placeholder={'Write Your Message *'}
                                />
                            </div>
                        </div>
                        <div className="other-option col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="form-submit-button">
                                <FormInput
                                    tag={'button'}
                                    val={'Send Message'}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default ContactForm
